import Udovibe from "../images/udovibe.png";
import ClothingStore from "../images/clothing store.png";

export const jobData = [
  {
    date: "2023-05-08",
    title: "Software Engineer",
    company: "Lowe's India",
    location: "Karnataka, IN",
    range: "May 2023 - Present",
    url: "https://www.lowes.com/",
    content: [
      "Developed a robust real-time stream analytics solution utilizing Kafka topics to ingest user data from various platforms, implementing metric creation and tracking functionalities, which enhance the accuracy of real-time analytics.",
      "Developed a REST API endpoints using Node.js and Fastify for user management, handling database, client-side, and backend code independently",
      "Contributed to Web Performance Optimization, leveraging innovative strategies and frameworks to achieve a 15% decrease in page load times and a 20% increase in overall website performance.",
    ],
  },
  {
    date: "2021-01-04",
    title: "Engineer",
    company: "L&T Technology Services",
    location: "Karnataka, IN",
    range: "Sep 2020 - April 2023",
    url: "https://www.ltts.com/",
    content: [
      "Developed small UI components to achieve 20% faster implementation and 80% more bug-free developmentusing React and Redux and applying the concept of micro frontend architecture.",
      " Built the logic for pagination storing 50,000+ records which resulted in high scalability and faster loading of web pages using infinite scroll and code-splitting techniques.",
      "Collaborate with designers, project managers, and other engineers to transform creative concepts into production realities for clients and stakeholders",
      " Built a windows and web application from scratch which will be used by 1000s of store employees to monitor windows tab health and battery management using MVVM design patterns.",
    ],
  },
];

export const featuredData = [
  {
    date: "1",
    title: "uDovibe",
    cover: Udovibe,
    external: "https://udovibe.com/",
    tech: [
      "React",
      "Styled Components",
      "Express",
      "GCP",
      "Elastic Email",
      "OAuth",
    ],
    content:
      "uDovibe is an e-commerce platform offering AI-generated posters. With over 15k monthly visitors, it blends art and technology seamlessly. Built on React, the website ensures a modern user experience. Firebase Firestore powers the database for efficient data handling. Google Analytics tracks user behavior, while Elastic Email manages email notifications. RazorPay Payment Gateway handles secure payments, enhancing the overall user journey on uDovibe.",
  },
  {
    date: "1",
    title: "Clothing Store",
    cover: ClothingStore,
    github:
      "https://github.com/saubhagyaashish/crown-clothing-ecommerce-webApp",
    external: "https://saubhagya-clothing-app.netlify.app/",
    tech: ["React", "Redux", "Sass", "Google Firebase", "OAuth", "Netlify"],
    content:
      "E-commerce Clothing Store is a project I developed using React for educational purposes. It’s an online clothing store application that utilizes Google Firestore DB for data management and Google Firebase for user authentication. The application also handles complex state management using Redux, providing a consistent and predictable state container for the app. This project showcases my skills in using modern web development technologies and practices.",
  },
];

export const blogData = [
  {
    date: "2021-08-19",
    title: "Redux Persist vs LocalStorage",
    external: "https://saubhagya-ashish.medium.com/redux-persist-vs-localstorage-64771adb762b",
    tech: ["React", "Redux", "Redux Persist"],
    showInProjects: true,
    content:
    "The blog post provides an in-depth comparison between Redux Persist and LocalStorage, elucidating their distinct features and applications in web development.",
  },
  {
    date: "2021-08-13",
    title: "React + Google Firestore: Complete Tutorial",
    github: "https://github.com/saubhagyaashish/react-firestore",
    external: "https://saubhagya-ashish.medium.com/react-google-firestore-complete-tutorial-df0cfd15f2cb",
    tech: ["React", "Firebase", "Firestore", "Google Cloud"],
    showInProjects: true,
    content:
    "A comprehensive guide on integrating Firebase with a React application and performing CRUD operations on Google Firestore. This serves as a valuable resource for developers seeking to leverage these technologies in their projects",
  },
];
