import "./App.css";
import Hero from "./Components/sections/hero";
import styled, { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from "./styles";
import About from "./Components/sections/about";
import Jobs from "./Components/sections/jobs";
import Featured from "./Components/sections/featured";
import Projects from "./Components/sections/projects";
import Contact from "./Components/sections/contact";
import Nav from "./Components/support-sections/Nav";
import { useEffect, useState } from "react";
import Social from "./Components/support-sections/social";
import Email from "./Components/support-sections/email";


const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const App = ({ children, location }) => {
  const isHome =true;
  const [isLoading, setIsLoading] = useState(isHome);

  // Sets target="_blank" rel="noopener noreferrer" on external links
  const handleExternalLinks = () => {
    const allLinks = Array.from(document.querySelectorAll('a'));
    if (allLinks.length > 0) {
      allLinks.forEach(link => {
        if (link.host !== window.location.host) {
          link.setAttribute('rel', 'noopener noreferrer');
          link.setAttribute('target', '_blank');
        }
      });
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (location.hash) {
      const id = location.hash.substring(1); // location.hash without the '#'
      setTimeout(() => {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView();
          el.focus();
        }
      }, 0);
    }

    handleExternalLinks();
  }, [isLoading]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StyledContent>
      <Nav isHome={isHome} />
      <Social isHome={isHome} />
      <Email isHome={isHome} />
      <Hero />
      <About />
      <Jobs />
      <Featured />
      <Projects />
      <Contact />
      </StyledContent>
    </ThemeProvider>
  );
};

export default App;
